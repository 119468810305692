import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sånn går du frem`}</h2>
    <p>{`Diskuter problemstillingen din i forum eller en av felleskanalene på Slack, for å høre om flere har det samme behovet. For eksempel i felles designforum, frontend- og arkitekturforum eller i Slack-kanalen `}<strong parentName="p">{`#ext-designsystem`}</strong>{`.`}</p>
    <p>{`Noen ganger er endringene så små at vi ikke trenger å snakke om dem i plenum først, men som hovedregel bør ønsker om endringer og nye komponenter diskuteres felles før de implementeres i designsystemet.`}</p>
    <p>{`Når ideen er moden til å tas inn i designsystemet – `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/issues/new/choose"
      }}>{`lag et issue på GitHub`}</a>{` eller `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/uSg1Lz02to7HEZNzqBqi3p/%C3%98nskeliste?type=design&node-id=14%3A22194&mode=design&t=d3mWkzjEra8DIp4L-1"
      }}>{`legg den inn i ønskelista`}</a>{` i Figma. `}</p>
    <h2>{`Kom gjerne med løsningsforslag`}</h2>
    <p>{`Den raskeste måten å få endringer eller nye komponenter inn i designsystemet på er å komme med et konkret løsningsforslag, som designsystem-teamet kan kvalitetssikre før det publiseres ut i designsystemet.`}</p>
    <p>{`Det er mange ulike ønsker blant alle som bruker designsystemet. For å kunne møte flest mulig av behovene er vi avhengige av at teamene bidrar aktivt inn med forslag til løsninger.`}</p>
    <p>{`Les mer om `}<a parentName="p" {...{
        "href": "/bidra/kom-med-losningsforslag/"
      }}>{`hvordan du kommer med forslag til løsninger`}</a>{` i designsystemet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      